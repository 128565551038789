<template>
  <form @submit.prevent="update">
    <modal-card title="Update task priority" :processing="isProcessing">
      <loading v-if="loading" />
      <div v-else>
        <b-message v-if="dbOption" type="is-dark">
          Your task priority is currently '<strong>{{
            dbOption.leftLabel
          }}</strong
          >'. You can change it here, but please note there are upsides and
          downsides to each.
        </b-message>

        <selector v-model="selectedOption" :items="priorities" :required="true">
          <strong slot="left-label" slot-scope="priority" class="is-size-7"
            >{{ priority.item.leftLabel
            }}<b-icon
              v-if="getPriorityIcon(priority.item.priority)"
              v-bind="getPriorityIcon(priority.item.priority)"
              class="has-margin-left-50"
          /></strong>
        </selector>
      </div>

      <button
        slot="footer"
        :class="{ 'is-loading': isProcessing }"
        :disabled="!validForm || isProcessing"
        type="submit"
        class="button is-success"
      >
        Update
      </button>
    </modal-card>
  </form>
</template>

<script>
import { priorities, getPriorityIcon } from "@src/data/task";
export default {
  name: "TaskPriortyModal",
  props: {
    taskId: {
      required: true,
      type: String
    }
  },
  data() {
    return {
      loading: true,
      isProcessing: false,
      selectedOption: null,
      dbOption: null
    };
  },
  computed: {
    task() {
      return this.$store.getters["tasks/task"](this.taskId) || {};
    },
    priorities() {
      return [
        {
          leftLabel: "General",
          desc:
            "General priority is suitable for most tasks. They will be worked on by engineers on shift, but may be transferred to other agents should the engineer finish their shift.",
          priority: priorities.GENERAL
        },
        {
          leftLabel: "Advanced / Complex",
          desc:
            "Advanced tasks are complicated and require in-depth work. They will often stick with the same agent to ensure consistency, but may take longer to complete.",
          priority: priorities.ADVANCED
        },
        {
          leftLabel: "High",
          desc:
            "High priority tasks relate to service affecting issues, such as a site being offline or infected with malware. They will be looked at with a matter of urgency.",
          priority: priorities.HIGH
        }
      ];
    },
    validForm() {
      return !!this.selectedOption;
    }
  },
  created() {
    this.$store
      .dispatch("tasks/observeTask", { taskId: this.taskId })
      .finally(() => {
        this.selectedOption =
          this.priorities.find(i => i.priority === this.task.priority) ||
          this.priorities[0];

        this.dbOption = { ...this.selectedOption };

        this.loading = false;
      });
  },
  beforeDestroy() {
    this.$store.dispatch("tasks/binTask", { taskId: this.taskId });
  },
  methods: {
    getPriorityIcon,
    update() {
      this.isProcessing = true;
      return this.$store
        .dispatch("tasks/updatePriority", {
          taskId: this.taskId,
          priority: this.selectedOption.priority
        })
        .then(() => {
          this.$emit("close");
        })
        .catch(error => {
          this.isProcessing = false;
          this.$toast.open({
            message: error.message,
            type: "is-danger"
          });
        });
    }
  }
};
</script>
